:root{
    --alternate-dark:rgb(11, 11, 11);
    --alternate-light:rgb(40, 40, 40);  
    --dark:rgb(0,0,0);
    --alternate-bg: white;
    --alternate-txt: black;
    --light-txt: #e9e9e9;
}
body{
    background-color: rgb(23, 23, 23);
    color: white;
}
*{
    scroll-behavior: smooth;
}
