.Navbar{
    height: 3rem;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    display: flex;
    white-space: nowrap;    
    align-items: center;
}

.logo{
    margin-top: 2rem;
    margin-left: 1rem;
    background-color: black;
    border: 1.5px solid black;
    padding: 0.7rem;
    border-radius: 50%;
}
#logo_img{
    object-fit: contain;
    width: calc(2rem + 1.5vw);
    height: calc(2rem + 1.5vw);
} 

.nav-menu{
    padding-right: 3rem;
    width: 100%;
    display: flex;
    float: right;
    list-style: none;
    justify-content: right;
}

.nav-items{
    color: inherit;
    text-decoration: none;
    padding: 3px;
    margin: 1rem 2rem;
}

.nav-items:hover{
    font-weight: 700;
    border-bottom: 1px solid;
    transition: all 0.1s ease-out;
}

.menu-icon{
    display: none;
}

.theme{
    padding-right: 1rem;
}

@media screen and (max-width: 550px) {
    .Navbar{
        height: auto;
        align-items: normal;
    }
    .theme{
        flex: content;
        text-align: right;
        padding-top:1rem;
        padding-right: 0.5rem;
    }
    .logo{
        height: min-content;
        margin: 0%;
        margin-top: .1rem;
    }
    #logo_img{
        object-fit: contain;
        width: calc(1rem + 3vw);
        height: calc(1rem + 3vw);
    } 
    .nav-menu{
        display: none;
    }
    .menu-icon{
        z-index: 1;
        display: block;
        position: absolute;
        top: 1.5rem;
        right: 3rem;
        cursor: pointer;
    }
    .active{
        display: block;
        position: relative;
        width: 100%;
        right: 1rem;
        text-align: center;
        transition: all 0.5s ease;
    }

    .nav-items{
        display: block;
        margin: 1rem;
        text-align: center;
    }
}