.About{
    align-content: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    padding: calc(6vw);
    padding-top: 0%;
}
.resume-btn{
    color: unset;
    text-decoration: none;
    font-size: calc(0.5rem + 0.75vw);
    font-family: unset;
    padding: .8rem;
    background-color: transparent;
    border: 2px solid;
    border-radius: 1rem;
}
.resume-btn:hover{
    box-shadow: 2px 2px 5px 0;
}

#hip{
    margin: 0%;
    font-weight: 600;
    text-decoration: none;
    border: none;
    font-size: calc(1rem + 3vw);
    padding-bottom: 0%;
}
.About h1{
    padding-bottom: 2rem;
    font-size: calc(2rem + 3vw);
    font-weight: 300;
    border-bottom: solid 2px;
    text-align: center;
    
}
.about-container{
    padding-right: 10%;
    padding-left: 10%;
    display: flex;
    align-items: center;
}

.animation{
    color: unset;
    text-decoration: none;
    padding-top: 3rem;
    padding-left: 3rem;
    text-align: left;
    margin: 0%;
    width: 70%;
}

.about-image{
    width: 20%;
}
.about-image img{
    width: 100%;
} 

.about{
    color: var(--light-txt);
    padding-top: 2rem;
    text-align: justify;
    font-size: calc(0.4rem + 1vw);
    font-weight: 300;
    padding-right: 10%;
    padding-left: 10%;
}

@media screen and (max-width:550px){
    .About{
        padding: 0px;
    }
    .About h1{
        padding-left:10%;
        /*font-size: 2rem;
        font-weight: 400; */
        text-align: left;
        
    }
    .about-container{
        margin: 0%;
        padding: 0;
        display: block;
    }
    .about-image{
        width:20%;
        position: absolute;
        transform: translate(calc(6rem + 17vw), calc(-5rem - 15vw));
    }
    .animation{
        width: auto;
        font-size: 1.5rem;
        height: fit-content;
        padding-top: 1rem;
        padding-left: 7%;
        padding-right: 7%;
    }
    .about{
        font-size: 4vw;  
        padding-left: 5%;
        padding-right: 5%;
    }
}

