.Header{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    padding: calc(1rem + 4vw);
}
.btn{
    text-decoration: none;
    font-family: unset;
    color: unset;
    font-weight: 400;
    font-size: calc(.5rem + 1vw);
    width: auto;
    padding: 1.5rem;
    background-color: transparent;
    border: solid 1px;
    border-radius: 5rem;
}
.btn:hover{
    box-shadow: 2px 2px 5px 0;
}

.container{
    font-weight: 500;
    font-size: calc(1rem + 6vw);
    display: flex;
    flex-direction:row;
    align-items: center;
    margin-bottom: 2rem;
}
.image{
    order:2;
    width: 30%;
    padding-left: 2rem;
}
.image img{
    width: 100%;
    border-radius: 50%;
}
.text{
    width: 70%;
}

@media screen and (max-width: 550px){
    .container{
        display: block;
        align-content: center;
    }
    .btn{
        margin-top: 10px;
        padding: 10px;
    }
    .image{
        padding: 0%;
        width: 100%;
        text-align: center;
    }
    .image img{
        width: 30%;
    }
    .text{
        margin-top: 2rem;
        width: 100%;
    }
}
