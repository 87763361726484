.Footer{
    margin: 0;
    background-color: var(--alternate-bg);
    color: var(--alternate-txt);
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    padding: calc(1rem + 4vw);
    padding-bottom: calc(1rem + 7vw);
    position: relative;
}

.Footer h1{
    margin: 0%;
    font-weight: 400;
    font-size: calc(1rem + 6vw);
}

.Footer h3{
    padding-left: 5%;
    padding-right:5% ;
    font-weight: 400;
    font-size: calc(0.5rem + 0.5vw);
    margin-bottom: 2rem;
}

.mail-btn{
    color: unset;
    text-decoration: none;
    font-size: calc(0.4rem + 0.5vw);
    font-family: unset;
    padding: calc(0.3rem + 0.5vw);;
    background-color: transparent;
    border: 2px solid;
    border-radius: 2rem;
    margin: 5rem;
}

.mail-btn:hover{
    box-shadow: 0 0 20px 0 var(--alternate-dark);
}

.icons{
    bottom: 0rem;
    left: 0%;
    width: 100%;
    position: absolute;
    align-content: center;
    display: flex;
    justify-content: center;
}

.icons a{
    height: fit-content;
    padding: 2vw;
    color: unset;
    text-decoration: none;
}

.icons i{
    margin: 0%;
    padding: 0%;
    font-size: 1.5rem;
}