.Projects{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    padding: calc(1rem + 4vw);
    padding-top: 0%;
    box-sizing: border-box;
}

.Projects h1{
    padding-bottom: 1rem;;
    font-size: calc(2rem + 3vw);
    font-weight: 300;
    border-bottom: solid 2px;
    text-align: center;
}

.projects-container{
    padding:2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(33.3% - 5rem), 1fr)); /* Change 300px to your desired min width */
    grid-gap: 2rem;
}
.card{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    box-sizing: border-box;
    width: 100%;
    
    background: linear-gradient(-45deg, var(--alternate-dark) 30%, var(--alternate-light) 100%);
    border-radius: 5px;
    
    position: relative;
    padding: 1rem;
    text-align: center;
}

.card:hover{
    box-shadow: 9px 12px 22px 7px var(--dark);
    -webkit-box-shadow: 9px 12px 22px 7px var(--dark);
    -moz-box-shadow: 9px 12px 22px 7px var(--dark);
    
}
.card h3{
    margin: 0%;
    margin-bottom: 1rem;
}
.card-image{
    border: 2px dashed;
    width: 100%;
    height: calc(10rem + 5vw);
}
.card-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top    ;
}

.card p{
    color: var(--light-txt);
    font-size: calc(.8rem + 0.2vw);
    text-align: justify;
    font-weight: 200;
}
.card-footer{
    /* position: absolute; */
    width: 100%;
    margin-top: auto;
}
.card-btn{
    text-decoration: none;
    white-space: nowrap;
    margin: 1rem;
    font-family: unset;
    color: unset;
    font-size: .8rem;
    width: auto;
    padding: .5rem;
    background-color: transparent;
    border: solid 1px;
    border-radius: 5rem;
}



@media screen and (max-width: 1200px) {
    .projects-container {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)); /* Adjust the number of columns for medium screens */
    }
}

@media screen and (max-width: 600px) {
    .projects-container {
        padding: 0;
        grid-template-columns: repeat(auto-fill, minmax(80%, 1fr)); /* Adjust the number of columns for small screens */
    }
}

/* 
@media screen and (max-width:550px){
    .Projects{
        padding: 0px;
    }
    .Projects h1{
        font-size: 10vw;
        padding-left:10%;
    }
    .card{
        position: absolute;
        padding: .5rem;
        width:90%;
        height: max-content;
    }
    .card h3{
        font-size: 5vw;
        margin-bottom: .5rem;
    }
    .card-image{
        border: 2px dashed;
        width: 100%;
        height: 50vw;
    }
    .card-image img{
        width: 100%;
        object-position: top    ;
    }
    
    .card p{
        font-weight: 200;
        font-size: 4vw;
    }
    .card-footer{
        position: relative;
        width: 100%;
        bottom: 0;
    }
    .card-btn{
        margin: 1rem;
        font-size: 4vw;
        padding: .2rem;
    }
}
 */
